import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import ProductGridSingle from "../../components/product/ProductGridSingle";
import { selectBestSellers } from "../../store/slices/product-slice";

const ProductGrid = ({ spaceBottomClass }) => {
  const products = useSelector(selectBestSellers);

  return products?.map((product, index) => (
    <div className="col-xl-3 col-md-6 col-lg-4 col-sm-6" key={index}>
      <ProductGridSingle
        spaceBottomClass={spaceBottomClass}
        product={product}
      />
    </div>
  ));
};

ProductGrid.propTypes = {
  spaceBottomClass: PropTypes.string,
};

export default ProductGrid;
