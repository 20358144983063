import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { deleteFromCart } from "../../../store/slices/cart-slice";
import { useTranslation } from "react-i18next";

const MenuCart = ({ isOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currency = useSelector((state) => state.currency);
  const { cartItems } = useSelector((state) => state.cart) || { cartItems: [] };
  let cartTotalPrice = 0;

  return (
    <div className={`shopping-cart-content${isOpen ? " active" : ""}`}>
      {cartItems && cartItems.length > 0 ? (
        <Fragment>
          <ul>
            {cartItems.map((item) => {
              const finalProductPrice = (
                item.unitPrice * currency.currencyRate
              ).toFixed(2);

              cartTotalPrice += item.unitPrice * item.quantity;

              return (
                <li
                  className="single-shopping-cart"
                  key={item.productVariantId}
                >
                  <div className="shopping-cart-img">
                    <Link
                      to={process.env.PUBLIC_URL + "/product/" + item.productId}
                    >
                      <img
                        alt={item.productName || "Product"}
                        src={
                          item.image?.imagePath ||
                          "https://upload.wikimedia.org/wikipedia/commons/3/3f/Placeholder_view_vector.svg"
                        }
                        className="img-fluid"
                        onError={(e) => {
                          e.target.src =
                            "https://upload.wikimedia.org/wikipedia/commons/3/3f/Placeholder_view_vector.svg";
                        }}
                      />
                    </Link>
                  </div>
                  <div className="shopping-cart-title">
                    <h4>
                      <Link
                        to={
                          process.env.PUBLIC_URL + "/product/" + item.productId
                        }
                      >
                        {item.productName || "Product"}
                      </Link>
                    </h4>
                    {item?.variantSpecs?.slice(0, 5).map((spec, index) => (
                      <span
                        key={spec}
                        className={"cart-menu-variant"}
                        style={{ fontSize: "10px" }}
                      >
                        {spec}
                        {index < item.variantSpecs.length - 1 ? " - " : ""}
                      </span>
                    ))}
                    <h6>
                      {t("components.header.menu-cart.quantity-item")}{" "}
                      {item.quantity}
                    </h6>
                    <span>{currency.currencySymbol + finalProductPrice}</span>
                    {item.selectedOptions &&
                      item.selectedOptions.length > 0 && (
                        <div className="cart-item-variation">
                          {item.selectedOptions.map((option, index) => (
                            <span key={index}>
                              {option.name}: {option.value}
                            </span>
                          ))}
                        </div>
                      )}
                  </div>
                  <div className="shopping-cart-delete">
                    <button
                      onClick={() =>
                        dispatch(deleteFromCart(item.productVariantId))
                      }
                    >
                      <i className="fa fa-times-circle" />
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="shopping-cart-total">
            <h4>
              {t("components.header.menu-cart.shopping-cart-total")}{" "}
              <span className="shop-total">
                {currency.currencySymbol + cartTotalPrice.toFixed(2)}
              </span>
            </h4>
          </div>
          <div className="shopping-cart-btn btn-hover text-center">
            <Link className="default-btn" to={process.env.PUBLIC_URL + "/cart"}>
              {t("components.header.menu-cart.shopping-cart.view")}
            </Link>
            <Link
              className="default-btn"
              to={process.env.PUBLIC_URL + "/checkout"}
            >
              {t("components.header.menu-cart.shopping-cart.checkout")}
            </Link>
          </div>
        </Fragment>
      ) : (
        <p className="text-center">
          {t("components.header.menu-cart.shopping-cart.empty-chart")}
        </p>
      )}
    </div>
  );
};

export default MenuCart;
