import apiService from "../services/apiService";

export const getAllProducts = (page = 0, size = 10) => {
  return apiService.get(`/api/admin/products/list?page=${page}&size=${size}`);
};

export const getProductById = (id) => {
  if (!id || id === "new") {
    return Promise.reject(new Error("Invalid product ID"));
  }
  return apiService.get(`/api/admin/products/${id}`);
};

export const getProductDetails = (productId) => {
  if (!productId) {
    return Promise.reject(new Error("Invalid product ID"));
  }
  return apiService.get("/api/user/products/details", { productId });
};

export const createProduct = (productData) => {
  const transformedData = {
    productName: productData.productName,
    productType: productData.productType,
    description: productData.description,
    additionalInfo: productData.additionalInfo,
    categoryId: productData.categoryId,
    variants: productData.variants.map((variant) => ({
      variantOptionIds: variant.variantOptionIds,
      price: Number(variant.price),
      stock: Number(variant.stock),
      isMainVariant: variant.isMainVariant,
      variantDescription: variant.variantDescription || "",
      sku: variant.sku,
    })),
  };
  return apiService.post(
    "/api/admin/products/variants/create",
    transformedData
  );
};

export const updateProduct = (id, productData) => {
  const transformedData = {
    productName: productData.productName,
    productType: productData.productType,
    description: productData.description,
    additionalInfo: productData.additionalInfo,
    categoryId: productData.categoryId,
    variants: productData.variants.map((variant) => ({
      variantOptionIds: variant.variantOptionIds,
      price: Number(variant.price),
      stock: Number(variant.stock),
      isMainVariant: variant.isMainVariant,
      variantDescription: variant.variantDescription || "",
      sku: variant.sku,
    })),
  };
  return apiService.put(`/api/admin/products/${id}`, transformedData);
};

export const deleteProduct = (id) =>
  apiService.delete(`/api/admin/products/${id}`);

export const getAllProductTypes = () =>
  apiService.get("/api/admin/products/types");

export const getProductTypeById = (id) =>
  apiService.get(`/api/admin/products/types/${id}`);

export const createProductType = (typeData) =>
  apiService.post("/api/admin/products/types", {
    name: typeData.name,
  });

export const updateProductType = (id, typeData) =>
  apiService.put(`/api/admin/products/types/${id}`, {
    name: typeData.name,
  });

export const deleteProductType = (id) =>
  apiService.delete(`/api/admin/products/types/${id}`);

export const getBestSellers = () =>
  apiService.public.get("/api/user/products/best-sellers");

export const getUserProductDetails = (productId) => {
  if (!productId) {
    return Promise.reject(new Error("Invalid product ID"));
  }
  return apiService.public.get("/api/user/products/details", { productId });
};

export const getShopProducts = (params = {}) => {
  const queryParams = new URLSearchParams({
    page: params.page || 0,
    size: params.size || 15,
    ...(params.searchParam && { searchParam: params.searchParam }),
    ...(params.categoryId && { categoryId: params.categoryId }),
    ...(params.sort && { sort: params.sort }),
  });

  return apiService.get(`/api/user/products/shops?${queryParams}`);
};
