import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import HeroSliderOne from "../../wrappers/hero-slider/HeroSliderOne";
import FeatureIcon from "../../wrappers/feature-icon/FeatureIcon";
import TabProduct from "../../wrappers/product/TabProduct";
import { store } from "../../store/store";
import { fetchBestSellers } from "../../store/slices/product-slice";

const Home = () => {
  return (
    <>
      <SEO titleTemplate="Design Your World" description="Parselife" />
      <LayoutOne
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-1"
      >
        {/* hero slider */}
        <HeroSliderOne />

        {/* featured icon */}
        <FeatureIcon spaceTopClass="pt-100" spaceBottomClass="pb-60" />

        {/* tab product */}
        <TabProduct spaceBottomClass="pb-60" />
      </LayoutOne>
    </>
  );
};

export async function homeLoader() {
  store.dispatch(fetchBestSellers());
  return null;
}

export default Home;
