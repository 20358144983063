import React, { forwardRef } from "react";
import PropTypes from "prop-types"
import cn from "clsx";
import { Navigation, Pagination, Autoplay, A11y } from "swiper";
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from "swiper/react";

const SwiperSlider = forwardRef(
    (
        {
            options,
            prevIcon,
            nextIcon,
            children,
            className,
            navClass,
            placheholder,
        },
        ref
    ) => {
        const modules = options?.modules !== undefined ? options.modules : [];
        const prevClass = `prev-${navClass || "swiper-nav"}`;
        const nextClass = `next-${navClass || "swiper-nav"}`;
        const sliderOptions = {
            slidesPerView: 1,
            spaceBetween: 0,
            loop: false,
            autoplay: options?.autoplay ? {
                delay: 2500,
                disableOnInteraction: false,
            } : false,
            watchSlidesProgress: true,
            autoHeight: true,
            breakpoints: {},
            ...options,
            modules: [Navigation, Pagination, A11y, Autoplay, ...modules],
            navigation: options?.navigation
                ? {
                    prevEl: `.${prevClass}`,
                    nextEl: `.${nextClass}`,
                }
                : false,
            pagination: options?.pagination
                ? {
                    clickable: true,
                }
                : false,
        };
        const placeholderComponent = (
            <div className="product-gallery">
                <div className="placeholder-wrapper">
                    <div className="placeholder-content">
                        <i className="pe-7s-photo"></i>
                        <p>No images available</p>
                    </div>
                </div>

                <style jsx>{`
                    .placeholder-wrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 450px;
                        border: 1px solid #ddd;
                        border-radius: 8px;
                        background: #f9f9f9;
                        margin-bottom: 20px;
                    }

                    .placeholder-content {
                        text-align: center;
                        color: #999;
                    }

                    .placeholder-content i {
                        font-size: 48px;
                        margin-bottom: 10px;
                    }

                    .placeholder-content p {
                        font-size: 16px;
                        font-weight: 500;
                    }
                `}</style>
            </div>
        );

        return (
            <div
                className={cn("swiper-wrap", className)}
                ref={ref}
            >
                <Swiper {...sliderOptions}>{placheholder ? placeholderComponent : children}</Swiper>

                {sliderOptions?.navigation && (
                    <>
                        <button
                            type="button"
                            className={`swiper-button-prev ht-swiper-button-nav ${prevClass}`}
                        >
                            <i className={cn(prevIcon, "icon")}/>
                        </button>
                        <button
                            type="button"
                            className={`swiper-button-next ht-swiper-button-nav ${nextClass}`}
                        >
                            <i className={cn(nextIcon, "icon")}/>
                        </button>
                    </>
                )}
            </div>
        );
    }
);

export {SwiperSlide};

SwiperSlider.propTypes = {
    options: PropTypes.shape({}),
    prevIcon: PropTypes.string,
    nextIcon: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    navClass: PropTypes.string,
}

SwiperSlider.defaultProps = {
    prevIcon: "pe-7s-angle-left",
    nextIcon: "pe-7s-angle-right",
    navStyle: 1,
    dotStyle: 1,
};

export default SwiperSlider;