import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectIsAuthenticated } from "../../../store/slices/auth-slice";
import styles from "./ImagesSection.module.css";
import imageService from "../../../services/imageService";
import productService from "../../../services/productService";
import productVariantPictureService from "../../../services/productVariantPictureService";
import { useTranslation } from "react-i18next";

const NoVariantImagesSection = () => {
  const { t } = useTranslation();
  const [images, setImages] = useState([]);
  const [productData, setProductData] = useState(null);
  const [productImages, setProductImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const queryParams = new URLSearchParams(location.search);
  const productId = queryParams.get("productId");

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/admin/login");
      return;
    }

    if (productId) {
      fetchProductData();
    }
  }, [productId, isAuthenticated, navigate]);

  const fetchProductData = async () => {
    try {
      setLoading(true);
      const [productResponse, imagesResponse] = await Promise.all([
        productService.getProductById(productId),
        imageService.getImagesByTableAndPackage("products", productId),
      ]);

      setProductData(productResponse.data.data);
      setImages(imagesResponse.data.data || []);

      if (productResponse.data.data?.productVariantId) {
        const variantImagesResponse =
          await productVariantPictureService.getImagesByVariantId(
            productResponse.data.data.productVariantId
          );

        const mappedImages =
          variantImagesResponse.data.data
            ?.map((item) => ({
              ...item,
              imagePath: item.imageEntity.imagePath,
              productVariantPictureId: item.productVariantPictureId,
            }))
            .sort((a, b) => a.orderValue - b.orderValue) || [];

        setProductImages(mappedImages);
      }
    } catch (err) {
      setError("Failed to fetch data: " + (err.message || "Unknown error"));
    } finally {
      setLoading(false);
    }
  };

  const handleFileSelect = async (event) => {
    const files = Array.from(event.target.files);
    if (!files.length) return;

    try {
      setLoading(true);
      setError(null);

      await imageService.uploadImage(files, "products", productId);
      const imagesResponse = await imageService.getImagesByTableAndPackage(
        "products",
        productId
      );
      setImages(imagesResponse.data.data || []);
    } catch (err) {
      setError("Failed to upload images: " + (err.message || "Unknown error"));
    } finally {
      setLoading(false);
    }
  };

  const handleImageSelect = async (image) => {
    if (!productData?.productVariantId) {
      setError("Product variant ID not found");
      return;
    }

    try {
      setLoading(true);

      const maxOrderValue = productImages.reduce(
        (max, img) => Math.max(max, img.orderValue),
        0
      );

      await productVariantPictureService.saveProductVariantPicture({
        productVariantId: productData.productVariantId,
        imageId: image.imageId,
        //order: productImages.length,
        order: maxOrderValue + 1,
      });

      const response = await productVariantPictureService.getImagesByVariantId(
        productData.productVariantId
      );

      const mappedImages =
        response.data.data
          ?.map((item) => ({
            ...item,
            imagePath: item.imageEntity.imagePath,
            productVariantPictureId: item.productVariantPictureId,
          }))
          .sort((a, b) => a.orderValue - b.orderValue) || [];

      setProductImages(mappedImages);
    } catch (err) {
      setError(
        "Failed to associate image with product: " +
          (err.message || "Unknown error")
      );
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveProductImage = async (variantImageId) => {
    if (!window.confirm("Are you sure you want to remove this image?")) {
      return;
    }

    try {
      setLoading(true);
      await productVariantPictureService.deleteByProductVariantPictureId(
        variantImageId
      );

      if (productData?.productVariantId) {
        const response =
          await productVariantPictureService.getImagesByVariantId(
            productData.productVariantId
          );

        const mappedImages =
          response.data.data
            ?.map((item) => ({
              ...item,
              imagePath: item.imageEntity.imagePath,
              productVariantPictureId: item.productVariantPictureId,
            }))
            .sort((a, b) => a.orderValue - b.orderValue) || [];

        setProductImages(mappedImages);
      }
    } catch (err) {
      setError("Failed to remove image: " + (err.message || "Unknown error"));
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteImage = async (imageId) => {
    if (!window.confirm("Are you sure you want to delete this image?")) {
      return;
    }

    try {
      setLoading(true);
      await imageService.deleteImage(imageId);
      const imagesResponse = await imageService.getImagesByTableAndPackage(
        "products",
        productId
      );
      setImages(imagesResponse.data.data || []);

      if (productData?.productVariantId) {
        const response =
          await productVariantPictureService.getImagesByVariantId(
            productData.productVariantId
          );

        const mappedImages =
          response.data.data
            ?.map((item) => ({
              ...item,
              imagePath: item.imageEntity.imagePath,
              productVariantPictureId: item.productVariantPictureId,
            }))
            .sort((a, b) => a.orderValue - b.orderValue) || [];

        setProductImages(mappedImages);
      }
    } catch (err) {
      setError("Failed to delete image: " + (err.message || "Unknown error"));
    } finally {
      setLoading(false);
    }
  };

  /*
    if (loading) {
        return <div className={styles.loading}>Loading...</div>;
    }
*/
  return (
    <div className={styles.container}>
      {loading && (
        <div className={styles.overlay}>
          <div className={styles.spinner}></div>
        </div>
      )}
      <div className={styles.header}>
        <h1>
          {t("admin.images.no-variant-images.styles-header")} /{" "}
          {productData?.productName}
        </h1>
        <div className={styles.uploadSection}>
          <input
            type="file"
            multiple
            accept="image/*"
            onChange={handleFileSelect}
            className={styles.fileInput}
            id="imageUpload"
          />
          <label htmlFor="imageUpload" className={styles.uploadButton}>
            {t("admin.images.no-variant-images.container.upload-button")}
          </label>
        </div>
      </div>

      {error && <div className={styles.error}>{error}</div>}

      <div className={styles.imagesSection}>
        <h2>{t("admin.images.no-variant-images.images-section.header")}</h2>
        <div className={styles.variantImages}>
          {productImages.map((productImage) => (
            <div
              key={productImage.productVariantPictureId}
              className={styles.variantImageCard}
            >
              <img src={productImage.imagePath} alt="Product" />
              <button
                onClick={() =>
                  handleRemoveProductImage(productImage.productVariantPictureId)
                }
                className={styles.removeButton}
              >
                {t(
                  "admin.images.no-variant-images.images-section.remove-button"
                )}
              </button>
            </div>
          ))}
        </div>

        <h2>
          {t("admin.images.no-variant-images.images-section.available-images")}
        </h2>
        <div className={styles.imageGrid}>
          {images.map((image) => (
            <div key={image.imageId} className={styles.imageCard}>
              <img src={image.imagePath} alt="Product" />
              <div className={styles.imageActions}>
                <button
                  onClick={() => handleImageSelect(image)}
                  className={styles.selectButton}
                  disabled={productImages.some(
                    (item) => item.imageId === image.imageId
                  )}
                >
                  {productImages.some((item) => item.imageId === image.imageId)
                    ? t(
                        "admin.images-section.styles-images-section.already-added"
                      )
                    : t(
                        "admin.images-section.styles-images-section.add-to-product"
                      )}
                </button>
                <button
                  onClick={() => handleDeleteImage(image.imageId)}
                  className={styles.deleteButton}
                >
                  {t(
                    "admin.images.no-variant-images.images-section-delete-button"
                  )}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NoVariantImagesSection;
