import PropTypes from "prop-types";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import Rating from "./sub-components/ProductRating";
import ProductModal from "./ProductModal";
import { useTranslation } from "react-i18next";

const ProductGridListSingle = ({ product, currency, spaceBottomClass }) => {
  const { t } = useTranslation();
  const [modalShow, setModalShow] = useState(false);
  const finalProductPrice = +(product.price * currency.currencyRate).toFixed(2);

  const placeholder = (
    <div className="placeholder-wrapper">
      <div className="placeholder-content">
        <i className="pe-7s-photo"></i>
        <p>{t("product.product-grid-list-single.place-holder-content")}</p>
      </div>
    </div>
  );

  return (
    <Fragment>
      <div className={clsx("product-wrap", spaceBottomClass)}>
        <div className="product-img">
          <Link to={process.env.PUBLIC_URL + "/product/" + product.id}>
            {product.image && product.image[0] ? (
              <img
                className="default-img"
                src={product.image[0]}
                alt={product.name}
                loading="lazy"
              />
            ) : (
              placeholder
            )}
            {product?.image?.length > 1 && (
              <img
                className="hover-img"
                src={product?.image[1]}
                alt={`${product?.name} Product alternate view`}
              />
            )}
          </Link>

          <div className="product-action">
            <div className="pro-same-action pro-cart">
              <Link
                to={`${process.env.PUBLIC_URL}/product/${product.id}`}
                className="view-details-button"
              >
                <i className="pe-7s-search" /> {t("product.product-grid-list-single.view-details-button")}
              </Link>
            </div>
            <div className="pro-same-action pro-quickview">
              <button onClick={() => setModalShow(true)} title="Quick View">
                <i className="pe-7s-look" />
              </button>
            </div>
          </div>
        </div>
        <div className="product-content text-center">
          <h3>
            <Link to={process.env.PUBLIC_URL + "/product/" + product.id}>
              {product.name}
            </Link>
          </h3>
          {product.rating && product.rating > 0 ? (
            <div className="product-rating">
              <Rating ratingValue={product.rating} />
            </div>
          ) : (
            ""
          )}
          <div className="product-price">
            <span>
              {currency.currencySymbol + finalProductPrice.toFixed(2)}
            </span>
          </div>
        </div>
      </div>
      <div className="shop-list-wrap mb-30">
        <div className="row">
          <div className="col-xl-4 col-md-5 col-sm-6">
            <div className="product-list-image-wrap">
              <div className="product-img">
                <Link to={process.env.PUBLIC_URL + "/product/" + product.id}>
                  {product.image && product.image[0] ? (
                    <img
                      className="default-img"
                      src={product.image[0]}
                      alt={product.name}
                      loading="lazy"
                    />
                  ) : (
                    <img
                      className="default-img"
                      src="https://upload.wikimedia.org/wikipedia/commons/3/3f/Placeholder_view_vector.svg"
                      alt={product.name}
                    />
                  )}
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-md-7 col-sm-6">
            <div className="shop-list-content">
              <h3>
                <Link to={process.env.PUBLIC_URL + "/product/" + product.id}>
                  {product.name}
                </Link>
              </h3>
              <div className="product-list-price">
                <span>{currency.currencySymbol + finalProductPrice} </span>
              </div>
              {product.rating && product.rating > 0 ? (
                <div className="rating-review">
                  <div className="product-list-rating">
                    <Rating ratingValue={product.rating} />
                  </div>
                </div>
              ) : (
                ""
              )}
              {product.shortDescription ? (
                <p>{product.shortDescription}</p>
              ) : (
                ""
              )}

              <div className="shop-list-actions d-flex align-items-center">
                <div className="shop-list-btn btn-hover">
                  <Link
                    to={`${process.env.PUBLIC_URL}/product/${product.id}`}
                    className="view-details-button"
                  >
                    <i className="pe-7s-search" /> {t("product.product-grid-list-single.view-details-button")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProductModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        productId={product.id}
      />
    </Fragment>
  );
};

ProductGridListSingle.propTypes = {
  currency: PropTypes.shape({}),
  product: PropTypes.shape({}),
  spaceBottomClass: PropTypes.string,
};

export default ProductGridListSingle;
