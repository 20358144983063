import apiService from "./apiService";

const productService = {
  getAllProducts: (page = 0, size = 10) => {
    return apiService.get(`/api/admin/products/list?page=${page}&size=${size}`);
  },

  getProductById: (id) => {
    if (!id || id === "new") {
      return Promise.reject(new Error("Invalid product ID"));
    }
    return apiService.get(`/api/admin/products/${id}`);
  },

  createProduct: (productData) => {
    const transformedData = {
      productName: productData.productName,
      productType: productData.productType,
      description: productData.description,
      additionalInfo: productData.additionalInfo,
      categoryId: productData.categoryId,
      mainVariantOptionId: productData.mainVariantOptionId,
      variants: productData.variants.map((variant) => ({
        variantOptionIds: variant.variantOptionIds,
        price: Number(variant.price),
        stock: Number(variant.stock),
        isMainVariant: variant.isMainVariant,
        variantDescription: variant.variantDescription || "",
        sku: variant.sku,
      })),
    };

    console.log("Creating product with data:", transformedData);
    return apiService.post(
      "/api/admin/products/variants/create",
      transformedData
    );
  },

  updateProduct: (id, productData) => {
    let transformedData = {}
    if(productData.productType === "Varyantlı") {
        transformedData = {
        productName: productData.productName,
        productType: productData.productType,
        description: productData.description,
        additionalInfo: productData.additionalInfo,
        categoryId: productData.categoryId,
        mainVariantOptionId: productData.mainVariantOptionId,
        mainVariantId: productData.variants.find(v => v.isMainVariant)?.productVariantId.startsWith("new") ? null : productData.variants.find(v => v.isMainVariant)?.productVariantId,
        variants: productData.variants.map((variant) => ({
          productVariantId: variant.productVariantId.startsWith("new") ? null : variant.productVariantId,
          variantOptionIds: variant.variantOptionIds,
          price: Number(variant.price),
          stock: Number(variant.stock),
          isMainVariant: variant.isMainVariant,
          variantDescription: variant.variantDescription || "",
          sku: variant.sku,
        })),
      };
    }
    else {
        transformedData = {
          productName: productData.productName,
          productType: productData.productType,
          description: productData.description,
          additionalInfo: productData.additionalInfo,
          categoryId: productData.categoryId,
          mainVariantOptionId: productData.mainVariantOptionId,
          variants: productData.variants.map((variant) => ({
            variantOptionIds: variant.variantOptionIds,
            productVariantId: variant.productVariantId,
            price: Number(variant.price),
            stock: Number(variant.stock),
            isMainVariant: variant.isMainVariant,
            variantDescription: variant.variantDescription || "",
            sku: variant.sku,
          }))
    }
    }


    console.log("Updating product with data:", transformedData);
    return apiService.put(`/api/admin/products/${id}`, transformedData);
  },

  deleteProduct: (id) => {
    return apiService.delete(`/api/admin/products/${id}`);
  },

  getAllProductTypes: () => {
    return apiService.get("/api/admin/products/types");
  },

  getProductTypeById: (id) => {
    return apiService.get(`/api/admin/products/types/${id}`);
  },

  createProductType: (typeData) => {
    return apiService.post("/api/admin/products/types", {
      name: typeData.name,
    });
  },

  updateProductType: (id, typeData) => {
    return apiService.put(`/api/admin/products/types/${id}`, {
      name: typeData.name,
    });
  },

  deleteProductType: (id) => {
    return apiService.delete(`/api/admin/products/types/${id}`);
  },
};

export default productService;
