import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

const ShopSearch = ({ onSearch, resetSearch }) => {
  const [searchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(
    searchParams.get("search") || ""
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (resetSearch) {
      setSearchTerm("");
    }
  }, [resetSearch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (onSearch) {
      onSearch(searchTerm);
    }
  };

  return (
    <div className="sidebar-widget">
      <h4 className="pro-sidebar-title">
        {t("components.product.shop-search.sidebar-title")}{" "}
      </h4>
      <div className="pro-sidebar-search mb-50 mt-25">
        <form className="pro-sidebar-search-form" onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder={t(
              "components.product.shop-search.sidebar-title.place-holder"
            )}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button type="submit">
            <i className="pe-7s-search" />
          </button>
        </form>
      </div>
    </div>
  );
};

ShopSearch.propTypes = {
  onSearch: PropTypes.func,
};

export default ShopSearch;
