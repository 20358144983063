import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import FooterCopyright from "../../components/footer/FooterCopyright";
import { useTranslation } from "react-i18next";

const FooterOne = ({
  backgroundColorClass,
  spaceTopClass,
  spaceBottomClass,
  spaceLeftClass,
  spaceRightClass,
  containerClass,
  extraFooterClass,
  sideMenu
}) => {
  const { t } = useTranslation();
  return (
    <footer className={clsx("footer-area", backgroundColorClass, spaceTopClass, spaceBottomClass, extraFooterClass, spaceLeftClass, spaceRightClass)}>
      <div className={`${containerClass ? containerClass : "container"}`}>
        <div className="row justify-content-center"> {/* Added flex-center class */}
          <div className={`col-md-3 col-sm-4`}> {/* Adjusted column widths */}
            {/* footer copyright */}
            <FooterCopyright
              footerLogo="/assets/img/logo/logo.png"
              spaceBottomClass="mb-30"
            />
          </div>
          <div className="col-md-3 col-sm-4">
            <div className="footer-widget mb-30 ml-30">
              <div className="footer-title">
                <h3>{t("about_us")}</h3>
              </div>
              <div className="footer-list">
                <ul>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/about"}>{t("about_us")}</Link>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-4">
            <div className="footer-widget mb-30 ml-30">
              <div className="footer-title">
                <h3>{t("contact_us")}</h3>
              </div>
              <div className="footer-list">
                <ul>
                <li>
                    <Link to={process.env.PUBLIC_URL + "/contact"}>{t("contact_us")}</Link>
                  </li>
              
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-4">
            <div className="footer-widget mb-30 ml-30">
              <div className="footer-title">
                <h3>{t("follow-us")}</h3>
              </div>
              <div className="footer-list">
                <ul>
                  <li><a href="//www.facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a></li>
                  <li><a href="//www.twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a></li>
                  <li><a href="//www.instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a></li>
                  <li><a href="//www.youtube.com" target="_blank" rel="noopener noreferrer">YouTube</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

FooterOne.propTypes = {
  backgroundColorClass: PropTypes.string,
  containerClass: PropTypes.string,
  extraFooterClass: PropTypes.string,
  sideMenu: PropTypes.bool,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  spaceRightClass: PropTypes.string
};

export default FooterOne;
