import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAllProducts,
  getProductById,
  createProduct,
  updateProduct,
  deleteProduct,
  getAllProductTypes,
  getProductTypeById,
  createProductType,
  updateProductType,
  deleteProductType,
  getBestSellers,
} from "../../api/productEndpoints";
import { logout } from "./auth-slice";

const initialState = {
  products: [],
  currentProduct: null,
  productTypes: [],
  currentProductType: null,
  loading: false,
  error: null,
  totalPages: 0,
  totalItems: 0,
  bestSellers: [],
};

// Products Thunks
export const fetchProducts = createAsyncThunk(
  "products/fetchAll",
  async ({ page = 0, size = 15 }, thunkAPI) => {
    try {
      const response = await getAllProducts(page, size);
      return response.data;
    } catch (error) {
      if (error.originalError.response.status === 401) {
        thunkAPI.dispatch(logout());
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchProductById = createAsyncThunk(
  "products/fetchById",
  async (id, thunkAPI) => {
    try {
      const response = await getProductById(id);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const createNewProduct = createAsyncThunk(
  "products/create",
  async (productData, thunkAPI) => {
    try {
      const response = await createProduct(productData);
      thunkAPI.dispatch(fetchProducts({}));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateExistingProduct = createAsyncThunk(
  "products/update",
  async ({ id, productData }, thunkAPI) => {
    try {
      const response = await updateProduct(id, productData);
      thunkAPI.dispatch(fetchProducts({}));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const removeProduct = createAsyncThunk(
  "products/delete",
  async ({ id, page }, thunkAPI) => {
    try {
      await deleteProduct(id);
      thunkAPI.dispatch(fetchProducts({ page: page - 1 }));
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Product Types Thunks
export const fetchProductTypes = createAsyncThunk(
  "products/fetchAllTypes",
  async (_, thunkAPI) => {
    try {
      const response = await getAllProductTypes();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchProductTypeById = createAsyncThunk(
  "products/fetchTypeById",
  async (id, thunkAPI) => {
    try {
      const response = await getProductTypeById(id);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const createNewProductType = createAsyncThunk(
  "products/createType",
  async (typeData, thunkAPI) => {
    try {
      const response = await createProductType(typeData);
      thunkAPI.dispatch(fetchProductTypes());
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateExistingProductType = createAsyncThunk(
  "products/updateType",
  async ({ id, typeData }, thunkAPI) => {
    try {
      const response = await updateProductType(id, typeData);
      thunkAPI.dispatch(fetchProductTypes());
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const removeProductType = createAsyncThunk(
  "products/deleteType",
  async (id, thunkAPI) => {
    try {
      await deleteProductType(id);
      thunkAPI.dispatch(fetchProductTypes());
      return id;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchBestSellers = createAsyncThunk(
  "products/fetchBestSellers",
  async (_, thunkAPI) => {
    try {
      const response = await getBestSellers();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setProducts(state, action) {
      state.products = action.payload;
    },
    clearProductError: (state) => {
      state.error = null;
      state.loading = false;
    },
    clearCurrentProduct: (state) => {
      state.currentProduct = null;
    },
    clearCurrentProductType: (state) => {
      state.currentProductType = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch all products
      .addCase(fetchProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload.data.products;
        state.totalPages = action.payload.pagination.totalPages;
        state.totalItems = action.payload.pagination.totalItems;
        state.error = null;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch product by ID
      .addCase(fetchProductById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProductById.fulfilled, (state, action) => {
        state.loading = false;
        state.currentProduct = action.payload;
        state.error = null;
      })
      .addCase(fetchProductById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Create product
      .addCase(createNewProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNewProduct.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(createNewProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Update product
      .addCase(updateExistingProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateExistingProduct.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateExistingProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Delete product
      .addCase(removeProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removeProduct.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(removeProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch all product types
      .addCase(fetchProductTypes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProductTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.productTypes = action.payload;
        state.error = null;
      })
      .addCase(fetchProductTypes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch product type by ID
      .addCase(fetchProductTypeById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProductTypeById.fulfilled, (state, action) => {
        state.loading = false;
        state.currentProductType = action.payload;
        state.error = null;
      })
      .addCase(fetchProductTypeById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Create product type
      .addCase(createNewProductType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNewProductType.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(createNewProductType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Update product type
      .addCase(updateExistingProductType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateExistingProductType.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateExistingProductType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Delete product type
      .addCase(removeProductType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removeProductType.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(removeProductType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //fetchBestSellers
      .addCase(fetchBestSellers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBestSellers.fulfilled, (state, action) => {
        state.loading = false;
        state.bestSellers = action.payload;
      })
      .addCase(fetchBestSellers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.data?.status?.description;
      });
  },
});

export const {
  setProducts,
  clearProductError,
  clearCurrentProduct,
  clearCurrentProductType,
} = productSlice.actions;

// Selectors
export const selectAllProducts = (state) => state.product?.products;
export const selectTotalPages = (state) => state.product?.totalPages;
export const selectTotalItems = (state) => state.product?.totalItems;
export const selectCurrentProduct = (state) => state.product?.currentProduct;
export const selectAllProductTypes = (state) => state.product?.productTypes;
export const selectCurrentProductType = (state) =>
  state.product?.currentProductType;
export const selectProductLoading = (state) => state.product?.loading;
export const selectProductError = (state) => state.product?.error;
export const selectBestSellers = (state) => state.product?.bestSellers;

export default productSlice.reducer;
