import { Link, useLoaderData, useNavigate, redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styles from "./ProductList.module.css";
import LinkButton from "../../../../components/button/LinkButton";
import { colors } from "../../../../constants/color";
import { selectIsAuthenticated } from "../../../../store/slices/auth-slice";
import {
  fetchProducts,
  removeProduct,
  selectAllProducts,
  selectProductError,
  selectProductLoading,
  selectTotalPages,
  selectTotalItems,
  clearProductError,
} from "../../../../store/slices/product-slice";
import { useTranslation } from "react-i18next";

const ProductList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { page, size } = useLoaderData();

  const products = useSelector(selectAllProducts);
  const loading = useSelector(selectProductLoading);
  const error = useSelector(selectProductError);
  const totalPages = useSelector(selectTotalPages);
  const totalItems = useSelector(selectTotalItems);

  const handleDelete = async (productId) => {
    if (!window.confirm("Are you sure you want to delete this product?")) {
      return;
    }

    await dispatch(removeProduct({ id: productId, page })).unwrap();

    if (products?.length === 1 && page > 1) {
      navigate(`?page=${page - 1}&size=${size}`);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      navigate(`?page=${newPage}&size=${size}`);
      //INFO: Bu yaklaşımın öncekinden neden daha iyi olduğunu anlatan maddeler:
      // URL always shows current page
      // Can bookmark specific pages
      // Browser back/forward buttons work naturally
      // Refreshing keeps you on the same page
      // State is more predictable
    }
  };

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.loading}>
          {t("admin.product.product-list.loading-container")}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.errorContainer}>
        <div className={styles.error}>
          Error: {error}
          <button
            onClick={() => {
              dispatch(clearProductError());
              dispatch(fetchProducts({ page: page - 1, size }));
            }}
            className={styles.retryButton}
          >
            {t("admin.product.product-list.loading-container.retry-button")}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>
          {t("admin.product.product-list.styles-container.styles-title")}
        </h1>
        <Link to="/admin-dashboard/products/new" className={styles.addButton}>
          {t("admin.product.product-list.styles-container.add-button")}
        </Link>
      </div>

      {products?.length === 0 ? (
        <div className={styles.noProducts}>
          {t("admin.product.product-list.styles-container.no-product")}
        </div>
      ) : (
        <>
          <table className={styles.table}>
            <thead>
              <tr>
                <th className={styles.tableHeader}>
                  {t("admin.product.product-list.styles-table.product-name")}
                </th>
                <th className={styles.tableHeader}>
                  {t("admin.product.product-list.styles-table.category")}
                </th>
                <th className={styles.tableHeader}>
                  {t("admin.product.product-list.styles-table.actions")}
                </th>
              </tr>
            </thead>
            <tbody>
              {products?.map((product) => (
                <tr key={product.productId} className={styles.tableRow}>
                  <td className={styles.tableCell}>{product.productName}</td>
                  <td className={styles.tableCell}>
                    {product.categoryName || "N/A"}
                  </td>
                  <td className={styles.tableCell}>
                    <div className={styles.actionButtons}>
                      <LinkButton
                        to={`/admin-dashboard/products/${product.productId}`}
                        text={t(
                          "admin.product.product-list.styles-table-cell.action-buttons.view"
                        )}
                        type={colors.primary}
                      />
                      <LinkButton
                        to={`/admin-dashboard/products/${product.productId}/edit`}
                        text={t(
                          "admin.product.product-list.styles-table-cell.action-buttons.edit"
                        )}
                        type={colors.warning}
                      />
                      <LinkButton
                        to={
                          product.productType === "Varyantlı"
                            ? `/admin-dashboard/images?productId=${product.productId}`
                            : `/admin-dashboard/novarimages?productId=${product.productId}`
                        }
                        text={t(
                          "admin.product.product-list.styles-table-cell.action-buttons.images"
                        )}
                        type={colors.info}
                      />
                      <button
                        onClick={() => handleDelete(product.productId)}
                        className={`${styles.deleteButton} ${styles.actionButton}`}
                      >
                        {t(
                          "admin.product.product-list.styles-table-cell.action-buttons"
                        )}
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className={styles.pagination}>
            <button
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
              className={styles.paginationButton}
            >
              {t("admin.product.product-list.pagination-button.previous")}
            </button>
            <span className={styles.pageInfo}>
              {t("admin.product.product-list.pagination-button.page-info.page")}{" "}
              {page}{" "}
              {t("admin.product.product-list.pagination-button.page-info.of")}{" "}
              {totalPages} ({totalItems}{" "}
              {t("admin.product.product-list.pagination-button.page-info.items")})
            </span>
            <button
              onClick={() => handlePageChange(page + 1)}
              disabled={page >= totalPages}
              className={styles.paginationButton}
            >
              {t("admin.product.product-list.pagination-button.next")}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export async function productListLoader({ request }) {
  const url = new URL(request.url);
  const page = parseInt(url.searchParams.get("page")) || 1;
  const size = 15;

  const { store } = await import("../../../../store/store");
  await store.dispatch(fetchProducts({ page: page - 1, size }));

  return { page, size };
}

export default ProductList;
