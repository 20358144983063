import apiService from "../services/apiService";

export const getAllCategories = () => apiService.get("/api/admin/categories");

export const getCategoryById = (id) =>
  apiService.get(`/api/admin/categories/${id}`);

export const createCategory = (categoryData) => {
  const formData = new FormData();
  formData.append("categoryName", categoryData.categoryName);
  formData.append(
    "categoryDescription",
    categoryData.categoryDescription || ""
  );
  formData.append("parentCategoryId", categoryData.parentCategoryId || "");

  return apiService.post("/api/admin/categories", formData);
};

export const updateCategory = (id, categoryData) => {
  const formData = new FormData();
  formData.append("categoryName", categoryData.categoryName);
  formData.append(
    "categoryDescription",
    categoryData.categoryDescription || ""
  );
  formData.append("parentCategoryId", categoryData.parentCategoryId || "");

  return apiService.put(`/api/admin/categories/${id}`, formData);
};

export const deleteCategory = (id) =>
  apiService.delete(`/api/admin/categories/${id}`);

export const getCategoryTree = () =>
  apiService.get("/api/admin/categories/tree");

export const getSubCategories = (parentId) =>
  apiService.get(`/api/admin/categories/parent/${parentId}`);

export const getUserCategories = () =>
  apiService.get("/api/user/categories");

