import React, { useEffect } from "react";
import PropTypes from "prop-types";

const ProductVariants = ({ productData, selectedOptions, onOptionChange }) => {
  const isHexColor = (str) => {
    return /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(str);
  };

  const getMainVariantInfo = () => {
    const mainProduct = productData?.find((p) => p.isMainVariant);
    if (!mainProduct) return null;

    const mainVariantType = mainProduct.variants.find(
      (v) => v.variantId === mainProduct.mainVariantOptionId
    );

    return {
      variantName: mainVariantType?.name || mainProduct.variants[0].name,
      variantId:
        mainProduct.mainVariantOptionId || mainProduct.variants[0].variantId,
    };
  };

  const mainVariantInfo = getMainVariantInfo();

  const getVariantTypes = () => {
    const variantTypes = {};
    productData?.forEach((variant) => {
      variant.variants.forEach((v) => {
        if (!variantTypes[v.name]) {
          variantTypes[v.name] = new Set();
        }
        variantTypes[v.name].add(
          JSON.stringify({
            name: v.options.optionName,
            content: v.options.optionContent,
            variantId: v.variantId,
          })
        );
      });
    });

    productData?.forEach((product) => {
      product.variants.forEach((v) => {
        if (!variantTypes[v.name]) {
          variantTypes[v.name] = new Set();
        }
      });
    });

    return Object.entries(variantTypes)
      .sort(([nameA], [nameB]) => {
        if (nameA === mainVariantInfo?.variantName) return -1;
        if (nameB === mainVariantInfo?.variantName) return 1;
        return 0;
      })
      .map(([name, optionsSet]) => ({
        name,
        options: Array.from(optionsSet).map((opt) => JSON.parse(opt)),
      }));
  };

  const getAvailableOptions = (variantType) => {
    if (variantType.name === mainVariantInfo?.variantName) {
      return variantType.options.map((opt) => opt.name);
    }

    const availableProducts = productData?.filter((variant) => {
      return Object.entries(selectedOptions).every(([name, value]) => {
        if (name === variantType.name) return true;
        return variant.variants.some(
          (v) => v.name === name && v.options.optionName === value
        );
      });
    });

    const availableOptions = new Set();
    availableProducts.forEach((variant) => {
      variant.variants.forEach((v) => {
        if (v.name === variantType.name) {
          availableOptions.add(v.options.optionName);
        }
      });
    });

    return Array.from(availableOptions);
  };

  useEffect(() => {
    if (
      !mainVariantInfo?.variantName ||
      !selectedOptions[mainVariantInfo.variantName]
    )
      return;

    const variantTypes = getVariantTypes();
    variantTypes.forEach((variantType) => {
      if (variantType.name !== mainVariantInfo.variantName) {
        const availableOptions = getAvailableOptions(variantType);
        if (
          availableOptions.length === 1 &&
          selectedOptions[variantType.name] !== availableOptions[0]
        ) {
          onOptionChange(variantType.name, availableOptions[0]);
        } else if (
          !availableOptions.includes(selectedOptions[variantType.name])
        ) {
          if (availableOptions.length > 0) {
            onOptionChange(variantType.name, availableOptions[0]);
          }
        }
      }
    });
  }, [selectedOptions[mainVariantInfo?.variantName]]);

  return (
    <div className="product-variants ">
      {getVariantTypes().map((variantType) => {
        const availableOptions = getAvailableOptions(variantType);
        return (
          <div key={variantType.name} className="variant-group ">
            <h4 className="variant-title">{variantType.name}</h4>

            <div className="variant-options">
              {variantType.options.map((option) => {
                const isAvailable =
                  option.name === mainVariantInfo.variantName
                    ? true
                    : availableOptions.includes(option.name);
                const isColorOption = isHexColor(option.content);
                const isSelected =
                  selectedOptions[variantType.name] === option.name;

                return (
                  <button
                    key={option.name}
                    className={`variant-button 
                                            ${isSelected ? "active" : ""} 
                                            ${!isAvailable ? "disabled" : ""} 
                                            ${isColorOption ? "color-variant" : ""}`}
                    onClick={() =>
                      isAvailable &&
                      onOptionChange(variantType.name, option.name)
                    }
                    disabled={!isAvailable}
                  >
                    {isColorOption ? (
                      <div
                        className="color-dot"
                        style={{ backgroundColor: option.content }}
                      />
                    ) : (
                      option.name
                    )}
                  </button>
                );
              })}
            </div>
          </div>
        );
      })}
      <div className="divider" />
      <style jsx>{`
        .product-variants {
          margin: 0rem 0;
          background-color: white;
          border-radius: 0.75rem;
        }

        .divider {
          width: 100%;
          height: 1px;
          background-color: #e5e7eb;
        }

        .variant-group {
          margin-bottom: 1.5rem;
        }

        .variant-group:last-child {
          margin-bottom: 0;
        }

        .variant-title {
          color: #374151;
          font-size: 0.875rem;
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: 0.025em;
          margin-bottom: 0.75rem;
        }

        .variant-options {
          display: flex;
          gap: 0.75rem;
          flex-wrap: wrap;
        }

        .variant-button {
          position: relative;
          padding: 0.625rem 1.25rem;
          border: 1px solid #e5e7eb;
          border-radius: 0.5rem;
          background-color: white;
          color: #4b5563;
          font-size: 0.875rem;
          font-weight: 500;
          cursor: pointer;
          transition: all 0.2s ease;
          overflow: hidden;
        }

        .variant-button.color-variant {
          padding: 0.25rem;
          width: 2.5rem;
          height: 2.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
        }

        .color-dot {
          width: 1.75rem;
          height: 1.75rem;
          border-radius: 50%;
          border: 2px solid white;
          box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
        }

        .variant-button.color-variant.active {
          padding: 0.35rem;
          border-color: #3b82f6;
        }

        .variant-button.color-variant.active .color-dot {
          width: 1.5rem;
          height: 1.5rem;
        }

        .variant-button::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: currentColor;
          opacity: 0;
          transition: opacity 0.2s ease;
        }

        .variant-button:not(.disabled):hover {
          border-color: #9ca3af;
          transform: translateY(-1px);
        }

        .variant-button:not(.disabled):hover::before {
          opacity: 0.05;
        }

        .variant-button:active {
          transform: translateY(0);
        }

        .variant-button.active:not(.color-variant):not(.disabled) {
          border-color: #3b82f6;
          background-color: #3b82f6;
          color: white;
          font-weight: 600;
        }

        .variant-button.active:not(.color-variant):not(.disabled):hover {
          background-color: #2563eb;
          border-color: #2563eb;
        }

        .variant-button.disabled {
          opacity: 0.5;
          cursor: not-allowed;
          background-color: #f3f4f6;
          border-color: #e5e7eb;
          color: #9ca3af;
        }

        .variant-button.disabled.active {
          background-color: #f3f4f6;
          color: #9ca3af;
          border-color: #e5e7eb;
        }

        @media (max-width: 640px) {
          .product-variants {
            padding: 1rem;
            margin: 1.5rem 0;
          }

          .variant-options {
            gap: 0.5rem;
          }

          .variant-button:not(.color-variant) {
            padding: 0.5rem 1rem;
            font-size: 0.8125rem;
          }
        }
      `}</style>
    </div>
  );
};

ProductVariants.propTypes = {
  productData: PropTypes.array.isRequired,
  selectedOptions: PropTypes.object.isRequired,
  onOptionChange: PropTypes.func.isRequired,
};

export default ProductVariants;
