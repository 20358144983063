import React, { Fragment, useState, useEffect } from "react";
import { useLocation, useLoaderData, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import cogoToast from "cogo-toast";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { addToCart } from "../../store/slices/cart-slice";
import { addToWishlist } from "../../store/slices/wishlist-slice";
import { getUserProductDetails } from "../../api/productEndpoints";
import ProductVariants from "./ProductVariants";
import ProductImageGallery from "./ProductImageGallery";
import ProductDescriptionTab from "../../wrappers/product/ProductDescriptionTab";
import ProductImageDescription from "../../wrappers/product/ProductImageDescription";
import { useTranslation } from "react-i18next";

const Product = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const response = useLoaderData();
  const [productData, setProductData] = useState(response.data.data);
  const { id } = useParams();

  useEffect(() => {
    setProductData(response.data.data);
  }, [id]);

  let isProduct;
  if (productData.productTypeName?.toLowerCase() === "varyantsız") {
    isProduct = true;
  } else {
    isProduct = false;
  }

  const mainVariant = isProduct
    ? productData
    : productData.find((variant) => variant.isMainVariant) || productData[0];

  const [selectedVariant, setSelectedVariant] = useState(() => {
    const productData = response?.data?.data;
    if (isProduct) {
      return;
    }
    return (
      productData?.find((variant) => variant.isMainVariant) || productData?.[0]
    );
  });

  const handleVariantChange = (newVariant) => {
    setSelectedVariant(newVariant);
  };

  if (!response?.data?.data.length && !isProduct) {
    return (
      <LayoutOne>
        <div className="product-area pt-100 pb-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-12 text-center">
                <i className="pe-7s-info"></i>
                <p>{t("pages.shop-product.product.product-area")}</p>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    );
  }

  return (
    <Fragment>
      <SEO
        titleTemplate={mainVariant.productName}
        description={mainVariant.description}
      />

      <LayoutOne>
        <Breadcrumb
          pages={[
            { label: t("home"), path: "/" },
            { label: t("shop"), path: "/shop" },
            //{ label: mainVariant.productName, path: pathname },
          ]}
        />

        <ProductImageDescription
          spaceTopClass="pt-100"
          spaceBottomClass="pb-40"
          product={mainVariant}
          productData={productData}
          allProductData={productData}
          onVariantChange={handleVariantChange}
          isProduct={isProduct}
        />

        <ProductDescriptionTab
          spaceBottomClass="pb-90"
          productFullDesc={
            isProduct ? productData.description : mainVariant.description
          }
          additionalInfo={
            isProduct ? productData.additionalInfo : mainVariant.additionalInfo
          }
          variants={isProduct ? null : selectedVariant?.variants}
          variantDescription={
            isProduct
              ? productData?.description
              : selectedVariant?.variantDescription
          }
          isProduct={isProduct}
        />
      </LayoutOne>
    </Fragment>
  );
};

export async function productDetailsLoader({ params }) {
  try {
    const response = await getUserProductDetails(params.id);
    return response;
  } catch (error) {
    cogoToast.error("Error loading product", {
      position: "top-center",
    });
    return { data: null };
  }
}

export default Product;
