import { Fragment, useState, useCallback, useEffect } from "react";
import {
  useLocation,
  useSubmit,
  useSearchParams,
  useLoaderData,
  Await,
  defer,
} from "react-router-dom";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import ShopSidebar from "../../wrappers/product/ShopSidebar";
import ShopTopbar from "../../wrappers/product/ShopTopbar";
import ShopProducts from "../../wrappers/product/ShopProducts";
import { getUserCategories } from "../../api/categoryEndpoints";
import { getShopProducts } from "../../api/productEndpoints";
import { useTranslation } from "react-i18next";

const Shop = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const submit = useSubmit();
  const [searchParams] = useSearchParams();
  const loaderData = useLoaderData();
  const [layout, setLayout] = useState("grid three-column");
  const [currentPage, setCurrentPage] = useState(
    parseInt(searchParams.get("page")) || 0
  );
  const [resetSearch, setResetSearch] = useState(false);
  const [filters, setFilters] = useState({
    searchParam: searchParams.get("search") || "",
    selectedCategory: searchParams.get("category") || "",
    sortOrder: searchParams.get("sort") || "default",
  });

  const getLayout = (layout) => {
    setLayout(layout);
  };

  useEffect(() => {
    if (resetSearch) {
      const timer = setTimeout(() => {
        setResetSearch(false);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [resetSearch]);

  const handleSearch = useCallback(
    (term) => {
      setCurrentPage(0);
      setFilters((prev) => ({ ...prev, searchParam: term }));
      submit(
        {
          search: term,
          category: filters.selectedCategory,
          sort: filters.sortOrder,
        },
        { replace: true }
      );
    },
    [submit, filters]
  );

  const handleCategoryChange = useCallback(
    (categoryId) => {
      setCurrentPage(0);
      setFilters((prev) => ({
        ...prev,
        selectedCategory: categoryId,
        searchParam: "",
      }));
      submit(
        {
          category: categoryId,
          sort: filters.sortOrder,
        },
        { replace: true }
      );
      setResetSearch(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    [submit, filters]
  );

  const handleSortChange = useCallback(
    (value) => {
      const sortValue =
        {
          priceHighToLow: "DESC",
          priceLowToHigh: "ASC",
        }[value] || "default";

      setCurrentPage(0);
      setFilters((prev) => ({ ...prev, sortOrder: sortValue }));
      submit(
        {
          search: filters.searchParam,
          category: filters.selectedCategory,
          sort: sortValue,
        },
        { replace: true }
      );
    },
    [submit, filters]
  );

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    submit(
      {
        page: pageNumber,
        search: filters.searchParam,
        category: filters.selectedCategory,
        sort: filters.sortOrder,
      },
      { replace: true }
    );
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Fragment>
      <SEO titleTemplate="Shop Page" description="Shop page of Parselife." />
      <LayoutOne>
        <Breadcrumb
          pages={[
            { label: t("home"), path: process.env.PUBLIC_URL + "/" },
            {
              label: t("shop"),
              path: process.env.PUBLIC_URL + location.pathname,
            },
          ]}
        />

        <div className="shop-area pt-95 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 order-1 order-lg-1">
                <Await resolve={loaderData.categories}>
                  {(categoriesResponse) => (
                    <ShopSidebar
                      categories={
                        categoriesResponse.data?.data[0]?.subCategories || []
                      }
                      onCategorySelect={handleCategoryChange}
                      onSearch={handleSearch}
                      selectedCategory={filters.selectedCategory}
                      sideSpaceClass="mr-30"
                      resetSearch={resetSearch}
                    />
                  )}
                </Await>
              </div>
              <div className="col-lg-9 order-2 order-lg-2">
                <Await resolve={loaderData.products}>
                  {(productsResponse) => {
                    const mappedProducts =
                      productsResponse.data.productList.map((product) => ({
                        id: product.productId,
                        name: product.productName,
                        price: product.price,
                        image: product.productImages || [],
                        stock: 999,
                        variation: false,
                        discount: 0,
                        new: false,
                        rating: 0,
                        saleCount: 0,
                        category: [],
                        shortDescription: "",
                        affiliateLink: null,
                        quantity: 1,
                      }));
                    const totalElements = productsResponse.data.totalElements;
                    const totalPages = productsResponse.data.totalPages;

                    return (
                      <>
                        <ShopTopbar
                          getLayout={getLayout}
                          getFilterSortParams={(type, value) => {
                            if (type === "filterSort") {
                              handleSortChange(value);
                            }
                          }}
                          productCount={totalElements}
                          sortedProductCount={mappedProducts.length}
                        />

                        <ShopProducts
                          layout={layout}
                          products={mappedProducts}
                        />

                        {totalPages > 1 && (
                          <div className="pro-pagination-style text-center mt-30">
                            <ul>
                              {currentPage > 0 && (
                                <li>
                                  <button
                                    onClick={() =>
                                      handlePageClick(currentPage - 1)
                                    }
                                    className="prev"
                                  >
                                    «
                                  </button>
                                </li>
                              )}

                              {Array.from({ length: totalPages }, (_, i) => (
                                <li key={i}>
                                  <button
                                    className={
                                      i === currentPage ? "active" : ""
                                    }
                                    onClick={() => handlePageClick(i)}
                                  >
                                    {i + 1}
                                  </button>
                                </li>
                              ))}

                              {currentPage < totalPages - 1 && (
                                <li>
                                  <button
                                    onClick={() =>
                                      handlePageClick(currentPage + 1)
                                    }
                                    className="next"
                                  >
                                    »
                                  </button>
                                </li>
                              )}
                            </ul>
                          </div>
                        )}
                      </>
                    );
                  }}
                </Await>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export async function shopLoader({ request }) {
  const PAGE_SIZE = 15;
  const url = new URL(request.url);
  const searchParams = new URLSearchParams(url.search);
  const search = searchParams.get("search") || "";
  const categoryId = searchParams.get("category") || "";
  const page = searchParams.get("page") || 0;
  const sort = searchParams.get("sort");

  const productsPromise = getShopProducts({
    page,
    size: PAGE_SIZE,
    searchParam: search || undefined,
    categoryId: categoryId || undefined,
    sort,
  });

  const categoriesPromise = getUserCategories();

  return defer({
    products: productsPromise,
    categories: categoriesPromise,
  });
}

export default Shop;
