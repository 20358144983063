import React, {useState} from "react";
import PropTypes from "prop-types";
import {EffectFade, Thumbs, FreeMode} from 'swiper';
import Swiper, {SwiperSlide} from "../../components/swiper";
import AnotherLightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";

const ProductImageGallery = ({images}) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxIndex, setLightboxIndex] = useState(0);

    const gallerySwiperParams = {
        spaceBetween: 10,
        loop: false,
        effect: "fade",
        fadeEffect: {
            crossFade: true
        },
        thumbs: {swiper: thumbsSwiper},
        modules: [EffectFade, Thumbs],
    };

    const thumbnailSwiperParams = {
        onSwiper: setThumbsSwiper,
        spaceBetween: 10,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesProgress: true,
        modules: [FreeMode, Thumbs],
        loop: false,
        slideToClickedSlide: true,
        navigation: true,
        breakpoints: {
            320: {
                slidesPerView: 4,
            },
            640: {
                slidesPerView: 4,
            },
            768: {
                slidesPerView: 4,
            },
            992: {
                slidesPerView: 4,
            },
            1200: {
                slidesPerView: 4,
            }
        }
    };

    const slides = images?.map(img => ({
        src: img.imagePath
    })) || [];

    return (
        <div className="product-gallery">
            <div className="product-large-image-wrapper">
                <Swiper options={gallerySwiperParams} placheholder={images.length === 0}>
                    {images.map((img, index) => (
                        <SwiperSlide key={index}>
                            <div className="product-image-container">
                                <button
                                    className="lightgallery-button"
                                    onClick={() => {
                                        setLightboxIndex(index);
                                        setLightboxOpen(true);
                                    }}
                                >
                                    <i className="pe-7s-expand1"></i>
                                </button>
                                <div className="single-image">
                                    <img
                                        src={img.imagePath}
                                        alt={`Product ${index + 1}`}
                                        className="img-fluid"
                                    />
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div className="product-small-image-wrapper">
                <Swiper options={thumbnailSwiperParams}>

                        {images.sort((a, b) => a.order - b.order).map((img, index) => (
                        <SwiperSlide key={index}>
                            <div className="thumbnail-wrapper">
                                <div className="thumbnail-inner">
                                    <img
                                        src={img.imagePath}
                                        alt={`Thumbnail ${index + 1}`}
                                        className="img-fluid"
                                    />
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

            <AnotherLightbox
                open={lightboxOpen}
                index={lightboxIndex}
                close={() => setLightboxOpen(false)}
                slides={slides}
                plugins={[Thumbnails, Zoom, Fullscreen]}
            />

            <style jsx>{`
                .product-gallery {
                    margin-bottom: 30px;
                }

                .product-large-image-wrapper {
                    border: 1px solid #ddd;
                    border-radius: 8px;
                    overflow: hidden;
                    margin-bottom: 20px;
                    background: #fff;
                }

                .product-image-container {
                    position: relative;
                    padding: 20px;
                }

                .single-image {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #fff;
                    height: 450px;
                }

                .single-image img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    max-height: 450px;
                }

                .lightgallery-button {
                    position: absolute;
                    top: 30px;
                    right: 30px;
                    background: rgba(255, 255, 255, 0.9);
                    border: none;
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    z-index: 1;
                    transition: all 0.3s;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                }

                .lightgallery-button:hover {
                    background: rgba(255, 255, 255, 1);
                    transform: scale(1.1);
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                }

                .product-small-image-wrapper {
                    padding: 0 25px;
                    margin-top: 20px;
                }

                .thumbnail-wrapper {
                    padding: 5px;
                    cursor: pointer;
                    transition: all 0.3s ease;
                }

                .thumbnail-inner {
                    position: relative;
                    width: 100%;
                    padding-top: 100%; /* 1:1 Aspect Ratio */
                    border: 2px solid transparent;
                    border-radius: 6px;
                    overflow: hidden;
                    background: #fff;
                }

                .thumbnail-inner img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    padding: 5px;
                }

                /* Swiper slide styles */
                :global(.swiper-slide-thumb-active) .thumbnail-inner {
                    border-color: #3b82f6;
                }

                :global(.swiper-button-prev),
                :global(.swiper-button-next) {
                    background: rgba(255, 255, 255, 0.9);
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                }

                :global(.swiper-button-prev:after),
                :global(.swiper-button-next:after) {
                    font-size: 14px;
                    color: #666;
                }

                :global(.swiper-button-prev:hover),
                :global(.swiper-button-next:hover) {
                    background: rgba(255, 255, 255, 1);
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                }

                @media (max-width: 768px) {
                    .single-image {
                        height: 350px;
                    }

                    .single-image img {
                        max-height: 350px;
                    }

                    .product-image-container {
                        padding: 10px;
                    }

                    .lightgallery-button {
                        top: 20px;
                        right: 20px;
                    }
                }
            `}</style>
        </div>
    );
};

ProductImageGallery.propTypes = {
    images: PropTypes.arrayOf(
        PropTypes.shape({
            imagePath: PropTypes.string.isRequired,
            order: PropTypes.number
        })
    ).isRequired
};

export default ProductImageGallery;