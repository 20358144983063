import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ShopTopAction = ({
  getFilterSortParams,
  productCount,
  sortedProductCount,
}) => {
  const { t } = useTranslation();
  return (
    <div className="shop-top-bar mb-35">
      <div className="select-shoing-wrap">
        <div className="shop-select">
          <select
            onChange={(e) => getFilterSortParams("filterSort", e.target.value)}
          >
            <option value="default">
              {t("components.product.shop-top-action.search-bar.default")}
            </option>
            <option value="priceHighToLow">
              {t("components.product.shop-top-action.price-high-to-low")}
            </option>
            <option value="priceLowToHigh">
              {t("components.product.shop-top-action.price-low-to-high")}
            </option>
          </select>
        </div>
        <p>
          {sortedProductCount} / {productCount}{" "}
          {t("components.product.shop-top-action.search-bar.result")}
        </p>
      </div>
    </div>
  );
};

ShopTopAction.propTypes = {
  getFilterSortParams: PropTypes.func,
  getLayout: PropTypes.func,
  productCount: PropTypes.number,
  sortedProductCount: PropTypes.number,
};

export default ShopTopAction;
