import PropTypes from "prop-types";
import ShopSearch from "../../components/product/ShopSearch";
import ShopCategories from "../../components/product/ShopCategories";

const ShopSidebar = ({
  categories,
  onCategorySelect,
  onSearch,
  selectedCategory,
  sideSpaceClass,
  resetSearch,
}) => {
  return (
    <div className={`sidebar-style ${sideSpaceClass || ""}`}>
      <ShopSearch onSearch={onSearch} resetSearch={resetSearch} />
      <ShopCategories
        categories={categories}
        getSortParams={onCategorySelect}
        selectedCategory={selectedCategory}
      />
    </div>
  );
};

ShopSidebar.propTypes = {
  categories: PropTypes.array,
  onCategorySelect: PropTypes.func,
  onSearch: PropTypes.func,
  selectedCategory: PropTypes.string,
  sideSpaceClass: PropTypes.string,
};

export default ShopSidebar;
