import cogoToast from "cogo-toast";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartItems: [
    // {
    //   "productVariantId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //   "quantity": 1,
    //   "unitPrice": 55,
    // },
    // {
    //   "productVariantId": "3fa85264-5717-4562-b3fc-2c963f66afa6",
    //   "quantity": 2,
    //   "unitPrice": 11,
    // }
  ],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const existingItem = state.cartItems.find(
        (item) => item.productVariantId === action.payload.productVariantId
      );

      if (existingItem) {
        existingItem.quantity += action.payload.quantity;
      } else {
        state.cartItems.push(action.payload);
      }
      cogoToast.success("Sepete Eklendi", { position: "bottom-left" });
    },
    deleteFromCart(state, action) {
      state.cartItems = state.cartItems.filter(
        (item) => item.productVariantId !== action.payload
      );
      cogoToast.error("Ürün Sepetten Kaldırıldı", { position: "bottom-left" });
    },
    decreaseQuantity(state, action) {
      const product = action.payload;
      if (product.quantity === 1) {
        state.cartItems = state.cartItems.filter(
          (item) => item.productVariantId !== product.productVariantId
        );
        cogoToast.error("Ürün Sepetten Kaldırıldı", {
          position: "bottom-left",
        });
      } else {
        state.cartItems = state.cartItems.map((item) =>
          item.productVariantId === product.productVariantId
            ? { ...item, quantity: item.quantity - 1 }
            : item
        );
        cogoToast.warn("Sepetten Bir Eksiltildi", {
          position: "bottom-left",
        });
      }
    },
    clearCart(state) {
      return initialState;
    },
  },
});

export const { addToCart, deleteFromCart, decreaseQuantity, clearCart } =
  cartSlice.actions;

export const selectAllCartItems = (state) => state.cart.cartItems;
export const selectCheckoutCartItems = (state) =>
  state.cart.cartItems.map((item) => ({
    productVariantId: item.productVariantId,
    quantity: item.quantity,
    unitPrice: item.unitPrice,
    productId : item.productId,
  }));
export const selectTotalCartPrice = (state) =>
  state.cart.cartItems.reduce(
    (total, item) => total + item.unitPrice * item.quantity,
    0
  );

export default cartSlice.reducer;
