import PropTypes from "prop-types";
import clsx from "clsx";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { useTranslation } from "react-i18next";

const ProductDescriptionTab = ({
                                   spaceBottomClass,
                                   productFullDesc,
                                   additionalInfo,
                                   variants,
                                   variantDescription,
                                   isProduct

                               }) => {
                                const { t } = useTranslation();
    return (
        <div className={clsx("description-review-area", spaceBottomClass)}>
            <div className="container">
                <div className="description-review-wrapper">
                    <Tab.Container defaultActiveKey={isProduct ? "additionalInfo" : "productFullDesc"}>
                        <Nav variant="pills" className="description-review-topbar">

                            {isProduct ?
                                null :
                                productFullDesc && (
                                <Nav.Item>
                                    <Nav.Link eventKey="productFullDesc">{t("components.product.description-tab.product-description")}</Nav.Link>
                                </Nav.Item>
                            )}
                            {additionalInfo && (
                                <Nav.Item>
                                    <Nav.Link eventKey="additionalInfo">
                                        {t("components.product.description-tab.additional-information")}
                                    </Nav.Link>
                                </Nav.Item>
                            )}

                        </Nav>
                        <Tab.Content className="description-review-bottom">
                            <Tab.Pane eventKey="productFullDesc">
                                {productFullDesc}
                            </Tab.Pane>

                            <Tab.Pane eventKey="additionalInfo">
                                {additionalInfo}
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>

            <style jsx>{`
                .additional-info-section {
                    margin-bottom: 30px;
                }

                .variants-info-section h4 {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 15px;
                }

                .variants-info-section ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                }

                .variants-info-section li {
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    margin-bottom: 10px;
                    padding: 8px 0;
                    border-bottom: 1px solid #eee;
                }

                .variants-info-section span {
                    font-weight: 500;
                    min-width: 120px;
                }

            `}</style>
        </div>
    );
};

ProductDescriptionTab.propTypes = {
    productFullDesc: PropTypes.string,
    additionalInfo: PropTypes.string,
    variants: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            options: PropTypes.shape({
                optionName: PropTypes.string,
                optionContent: PropTypes.string
            })
        })
    ),
    spaceBottomClass: PropTypes.string
};

export default ProductDescriptionTab;