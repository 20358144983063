import PropTypes from "prop-types";
import clsx from "clsx";
import SectionTitle from "../../components/section-title/SectionTitle";
import ProductGrid from "./ProductGrid";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const TabProduct = ({ spaceTopClass, spaceBottomClass, bgColorClass }) => {
  const { t } = useTranslation();
  return (
    <div
      className={clsx(
        "product-area",
        spaceTopClass,
        spaceBottomClass,
        bgColorClass
      )}
    >
      <div className="container">
        <SectionTitle
          titleText={t("best-seller-container-title")}
          positionClass="text-center"
          spaceClass="mb-30"
        />
        <div className="row">
          <ProductGrid spaceBottomClass="mb-25" />
        </div>
        <div className="view-more text-center mt-20 toggle-btn6 col-12">
          <Link className="loadMore6" to={process.env.PUBLIC_URL + "/shop"}>
            {t("home-page.view-more-products-text-button")}
          </Link>
        </div>
      </div>
    </div>
  );
};

TabProduct.propTypes = {
  bgColorClass: PropTypes.string,
  category: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
};

export default TabProduct;
