import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { useEffect, useState } from "react";
// Make sure path is correct
import ProductDescriptionInfo from "../../components/product/ProductDescriptionInfo";
import ProductImageGallery from "../../components/product/ProductImageGallery";

const ProductImageDescription = ({
  spaceTopClass,
  spaceBottomClass,
  product,
  allProductData,
  onVariantChange,
  isProduct,
}) => {
  const currency = useSelector((state) => state.currency);
  const [currentImages, setCurrentImages] = useState(product.images);

  useEffect(() => {
    if (product?.images) {
      setCurrentImages(product.images);
    }
  }, [product]);

  const handleVariantChange = (newVariant) => {
    console.log("New variant images:", newVariant.images);
    setCurrentImages(newVariant.images);

    if (onVariantChange) {
      onVariantChange(newVariant);
    }
  };

  return (
    <div className={clsx("shop-area", spaceTopClass, spaceBottomClass)}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <ProductImageGallery images={currentImages} />
          </div>
          <div className="col-lg-6 col-md-6">
            <ProductDescriptionInfo
              product={product}
              productData={allProductData}
              currency={currency}
              onVariantChange={handleVariantChange}
              isProduct={isProduct}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ProductImageDescription.propTypes = {
  spaceTopClass: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  product: PropTypes.object,
  allProductData: PropTypes.array,
};

export default ProductImageDescription;
