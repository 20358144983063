import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../store/slices/cart-slice";
import { useTranslation } from "react-i18next";
import ProductVariants from "../../pages/shop-product/ProductVariants";

const ProductDescriptionInfo = ({
  product,
  productData,
  currency,
  onVariantChange,
  isProduct,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.cartItems) || [];
  const [selectedVariant, setSelectedVariant] = useState(product);
  const [selectedOptions, setSelectedOptions] = useState(() => {
    const initialOptions = {};
    if (isProduct) {
      productData.variants?.forEach((variant) => {
        initialOptions[variant.name] = variant.options.optionName;
      });
    }
    product?.variants?.forEach((variant) => {
      initialOptions[variant.name] = variant.options.optionName;
    });
    return initialOptions;
  });
  const [quantityCount, setQuantityCount] = useState(1);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

  useEffect(() => {
    setSelectedVariant(product);
    setQuantityCount(1);
    setIsDescriptionExpanded(false);

    const initialOptions = {};
    if (isProduct) {
      productData.variants?.forEach((variant) => {
        initialOptions[variant.name] = variant.options.optionName;
      });
    } else {
      product?.variants?.forEach((variant) => {
        initialOptions[variant.name] = variant.options.optionName;
      });
    }
    setSelectedOptions(initialOptions);
  }, [product, productData, isProduct]);

  const getCartQuantity = () => {
    if (!cartItems) return 0;
    const cartItem = cartItems.find(
      (item) => item.productVariantId === selectedVariant?.productVariantId
    );
    return cartItem ? cartItem.quantity : 0;
  };

  const handleOptionChange = (variantName, optionValue) => {
    const newSelectedOptions = {
      ...selectedOptions,
      [variantName]: optionValue,
    };
    setSelectedOptions(newSelectedOptions);

    const matchingVariant = productData.find((variant) =>
      variant.variants.every(
        (v) => newSelectedOptions[v.name] === v.options.optionName
      )
    );

    if (matchingVariant) {
      setSelectedVariant(matchingVariant);
      setQuantityCount(1);
      onVariantChange(matchingVariant);
    }
  };

  const handleAddToCart = () => {
    const variantSpecs = selectedVariant?.variants?.map((variant) => {
      return variant.options.optionName;
    });
    const cartItem = {
      productId: selectedVariant?.productId,
      productVariantId: selectedVariant?.productVariantId,
      quantity: quantityCount,
      unitPrice: selectedVariant?.price,
      // Add productName for display
      productName: selectedVariant?.productName,
      image: selectedVariant?.images[0],
      stock: selectedVariant?.stock,
      variantSpecs: variantSpecs,
    };

    dispatch(addToCart(cartItem));
  };

  const toggleDescription = () => {
    setIsDescriptionExpanded(!isDescriptionExpanded);
  };

  const renderDescription = () => {
    if (isProduct) {
      if (productData.description.length <= 500) {
        return product.description;
      }
      return isDescriptionExpanded
        ? `${productData.description} `
        : `${productData.description.substring(0, 500)}... `;
    }
    const fullDescription = selectedVariant?.variantDescription || "";
    if (fullDescription.length <= 500) {
      return fullDescription;
    }

    return isDescriptionExpanded
      ? `${fullDescription} `
      : `${fullDescription.substring(0, 500)}... `;
  };

  return (
    <div className="product-details-content ml-70">
      <h2>{selectedVariant?.productName}</h2>
      <div className="product-details-price">
        <span>
          {currency.currencySymbol + selectedVariant?.price.toFixed(2)}
        </span>
      </div>
      {!isProduct && (
        <div className="pro-details-size-color">
          <ProductVariants
            productData={productData}
            selectedOptions={selectedOptions}
            onOptionChange={handleOptionChange}
          />
        </div>
      )}

      <div className="pro-details-quality">
        <div className="cart-plus-minus">
          <button
            onClick={() =>
              setQuantityCount(quantityCount > 1 ? quantityCount - 1 : 1)
            }
            className="dec qtybutton"
          >
            -
          </button>
          <input
            className="cart-plus-minus-box"
            type="text"
            value={quantityCount}
            readOnly
          />
          <button
            onClick={() =>
              setQuantityCount(
                quantityCount < selectedVariant?.stock - getCartQuantity()
                  ? quantityCount + 1
                  : quantityCount
              )
            }
            className="inc qtybutton"
          >
            +
          </button>
        </div>
        <div className="pro-details-cart btn-hover">
          {selectedVariant?.stock > 0 ? (
            <button
              onClick={handleAddToCart}
              disabled={getCartQuantity() >= selectedVariant?.stock}
            >
              {t("button.add-to-cart")}
            </button>
          ) : (
            <button disabled>{t("components.product.product-description.out-of-stock-button")}</button>
          )}
        </div>
      </div>

      <div className="pro-details-list">
        <p>
          {renderDescription()}
          {isProduct
            ? productData.description.length > 500 && (
                <span
                  className="toggle-description"
                  onClick={toggleDescription}
                >
                  {isDescriptionExpanded ? "▲ Show Less" : "▼ Show More"}
                </span>
              )
            : selectedVariant?.variantDescription.length > 500 && (
                <span
                  className="toggle-description"
                  onClick={toggleDescription}
                >
                  {isDescriptionExpanded ? "▲ Show Less" : "▼ Show More"}
                </span>
              )}
        </p>
      </div>

      {selectedVariant?.parentCategoryNames &&
        selectedVariant?.parentCategoryNames.length > 0 && (
          <div className="pro-details-meta">
            <span>{t("components.product.product-description.pro-details-meta")}</span>
            <ul>
              {selectedVariant?.parentCategoryNames.map(
                (category, index) =>
                  category !== "MASTER" && <li key={index}>{category}</li>
              )}
            </ul>
          </div>
        )}
    </div>
  );
};

ProductDescriptionInfo.propTypes = {
  currency: PropTypes.shape({}),
  product: PropTypes.object.isRequired,
  productData: PropTypes.array.isRequired,
  onVariantChange: PropTypes.func.isRequired,
};

export default ProductDescriptionInfo;
