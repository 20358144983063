import axios from "axios";
import { getItemFromLocalStorage } from "../utils/localStorageUtils";

const instance = axios.create({
  baseURL: "https://api.parselife.com/parselife",
  timeout: 60000,
});

instance.interceptors.request.use((config) => {
  if (config.skipAuth) {
    return config;
  } else {
    const token = getItemFromLocalStorage("auth_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }
  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => handleError(error)
);

const handleError = (error) => {
  let errorMessage = "An unexpected error occurred";

  if (error.response) {
    // The request was made and the server responded with a status code that falls out of the range of 2xx
    switch (error.response.status) {
      case 400:
        errorMessage = error.response.data.status.description ? error.response.data.status.description : "Bad request. Please check your input.";
        break;
      case 401:
        errorMessage = "Unauthorized. Please log in again.";
        break;
      case 403:
        errorMessage =
          "Access forbidden. You don't have permission for this action.";
        break;
      case 404:
        errorMessage = "Resource not found.";
        break;
      case 500:
        errorMessage = "Internal server error. Please try again later.";
        break;
      default:
        errorMessage = `Server responded with error: ${error.response.status}`;
    }
  } else if (error.request) {
    //INFO: (error.request) -> This exists when the request was made, but no response was received..
    errorMessage =
      "No response received from server. Please check your internet connection.";
  } else {
    // Something happened in setting up the request that triggered an Error
    errorMessage = "Error in setting up the request: " + error.message;
  }

  console.error("API Error:", errorMessage, error);
  //TODO: we can add a toast notification here

  return Promise.reject({ message: errorMessage, originalError: error });
};

const apiService = {
  get: (url, params = {}, config = {}) =>
    instance.get(url, { ...config, params }),
  post: (url, data, config = {}) => instance.post(url, data, config),
  put: (url, data, config = {}) => instance.put(url, data, config),
  delete: (url, config = {}) => instance.delete(url, config),

  public: {
    get: (url, params = {}, config = {}) =>
      instance.get(url, { ...config, params, skipAuth: true }),
    post: (url, data, config = {}) =>
      instance.post(url, data, { ...config, skipAuth: true }),
    put: (url, data, config = {}) =>
      instance.put(url, data, { ...config, skipAuth: true }),
    delete: (url, config = {}) =>
      instance.delete(url, { ...config, skipAuth: true }),
  },
};

export default apiService;
