import apiService from "./apiService";

const imageService = {
  getAllImages: () => {
    return apiService.get("/api/admin/s3/images");
  },

  getImageById: (id) => {
    if (!id) {
      return Promise.reject(new Error("Invalid image ID"));
    }
    return apiService.get(`/api/admin/s3/images/${id}`);
  },

  getImagesByTableAndPackage: (tableName, packageId) => {
    if (!tableName || !packageId) {
      return Promise.reject(
        new Error("Table name and package ID are required")
      );
    }
    return apiService.get(
      `/api/admin/s3/images/package/${packageId}`
    );
  },

  getImagesByPackage: (packageId) => {
    if (!packageId) {
      return Promise.reject(new Error("Package ID is required"));
    }
    return apiService.get(`/api/admin/s3/images/package/${packageId}`);
  },

  uploadImage: (imageFiles, tableName, packageId) => {
    if (!imageFiles || !tableName || !packageId) {
      return Promise.reject(
        new Error("Image file, table name, and package ID are required")
      );
    }

    const formData = new FormData();
    if (Array.isArray(imageFiles)) {
      imageFiles.forEach(file => {
        formData.append("images", file);
      });
    } else {
      formData.append("images", imageFiles);
    }

    return apiService.post(`/api/admin/s3/images/multiple?tableName=${tableName}&packageId=${packageId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  uploadMultipleImages: (files, tableName, packageId) => {
    if (!files || !files.length || !tableName || !packageId) {
      return Promise.reject(
        new Error("Files, table name, and package ID are required")
      );
    }

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });

    return apiService.post(
      `/api/admin/s3/images?table_name=${tableName}&package_id=${packageId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },

  deleteImage: (id) => {
    if (!id) {
      return Promise.reject(new Error("Invalid image ID"));
    }
    return apiService.delete(`/api/admin/s3/images/${id}`);
  },

  deleteImagesByPackage: (packageId) => {
    if (!packageId) {
      return Promise.reject(new Error("Package ID is required"));
    }
    return apiService.delete(`/api/admin/s3/images/package/${packageId}`);
  },
};

export default imageService;
