import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const CategoryItem = ({ category, getSortParams, selectedCategory, level = 0 }) => {
    
    const [isExpanded, setIsExpanded] = useState(false);
    const hasSubCategories = category.subCategories && category.subCategories.length > 0;
    const visibleSubCategories = hasSubCategories
        ? category.subCategories.filter(subCategory => subCategory.productCount > 0)
        : [];
    const isSelected = selectedCategory === category.categoryId;

    const handleCategoryClick = () => {
        if (getSortParams) {
            getSortParams(category.categoryId);
        }
    };

    const handleExpandClick = (e) => {
        e.stopPropagation();
        setIsExpanded(!isExpanded);
    };

    return (
        <div className={`category-item ${level > 0 ? 'sub-category' : ''}`}>
            <div
                className={`sidebar-widget-list-left ${isSelected ? 'active' : ''}`}
                style={{ paddingLeft: `${level * 20}px` }}
            >
                <button onClick={handleCategoryClick}>
                    {visibleSubCategories.length > 0 && (
                        <span
                            onClick={handleExpandClick}
                            className="expand-button"
                        >
                            {isExpanded ? '-' : '+'}
                        </span>
                    )}
                    <span className={`checkmark ${isSelected ? 'active' : ''}`} />
                    {category.categoryName}
                    {category.productCount > 0 && (
                        <span className="product-count">({category.productCount})</span>
                    )}
                </button>
            </div>

            {visibleSubCategories.length > 0 && isExpanded && (
                <div className="sub-categories">
                    {visibleSubCategories.map((subCategory) => (
                        <CategoryItem
                            key={subCategory.categoryId}
                            category={subCategory}
                            getSortParams={getSortParams}
                            selectedCategory={selectedCategory}
                            level={level + 1}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

const ShopCategories = ({ categories, getSortParams, selectedCategory }) => {
    const { t } = useTranslation();
    return (
        <div className="sidebar-widget mb-50">
            <h4 className="pro-sidebar-title">{t("product.shop-category.pro-sidebar-title.category")}</h4>
            <div className="sidebar-widget-list mt-30">
                {categories?.length > 0 ? (
                    <div>
                        <div className="sidebar-widget-list-left">
                            <button onClick={() => getSortParams("")}>
                                <span className={`checkmark ${!selectedCategory ? 'active' : ''}`} />
                                {t("product.shop-category.pro-sidebar-title.all-category")}
                            </button>
                        </div>
                        {categories.map((category) => (
                            <CategoryItem
                                key={category.categoryId}
                                category={category}
                                getSortParams={getSortParams}
                                selectedCategory={selectedCategory}
                            />
                        ))}
                    </div>
                ) : (
                    <p>{t("product.shop-category.pro-sidebar-title.no-category")}</p>
                )}
            </div>
        </div>
    );
};

ShopCategories.propTypes = {
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            categoryId: PropTypes.string,
            categoryName: PropTypes.string,
            categoryDescription: PropTypes.string,
            productCount: PropTypes.number,
            subCategories: PropTypes.array
        })
    ),
    getSortParams: PropTypes.func,
    selectedCategory: PropTypes.string
};

export default ShopCategories;