import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import ProductImageGallery from "./ProductImageGallery";
import ProductDescriptionInfo from "./ProductDescriptionInfo";
import { getUserProductDetails } from "../../api/productEndpoints";

function ProductModal({ show, onHide, productId, productVariantId }) {
  const currency = useSelector((state) => state.currency);

  const [product, setProduct] = useState(null);
  const [mainVariant, setMainVariant] = useState(null);
  const [currentImages, setCurrentImages] = useState([]);
  const [isProduct, setIsProduct] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!show) {
      return;
    }
    const fetchProductDetails = async () => {
      setIsLoading(true);
      try {
        const {
          data: { data },
        } = await getUserProductDetails(productId);
        if (data.productTypeName?.toLowerCase() === "varyantsız") {
          setIsProduct(true);
          setMainVariant(data);
          setCurrentImages(data.images);
        }
        setProduct(data);
        if (productVariantId) {
          const bestSellerProduct = data.find(
            (variant) => variant.productVariantId === productVariantId
          );
          setMainVariant(bestSellerProduct);
          setCurrentImages(bestSellerProduct.images);
          return;
        }
        setMainVariant(
          data.find((variant) => variant.isMainVariant) || data[0]
        );
        setCurrentImages(data?.find((variant) => variant.isMainVariant).images);
      } catch (error) {
        console.error("ProductModal fetchProductDetails error:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchProductDetails();
  }, [productId, productVariantId, show, onHide]);

  const onCloseModal = () => {
    onHide();
  };

  const handleVariantChange = (newVariant) => {
    setCurrentImages(newVariant.images);
  };

  return (
    <Modal
      show={show}
      onHide={onCloseModal}
      className="product-quickview-modal-wrapper"
    >
      <Modal.Header closeButton />
      <div className="modal-body">
        {isLoading ? (
          <div className="modal-loader-container">
            <div className="flone-preloader">
              <span></span>
              <span></span>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <ProductImageGallery images={currentImages} />
            </div>
            <div className="col-lg-6 col-md-6">
              <ProductDescriptionInfo
                product={mainVariant}
                productData={product}
                currency={currency}
                onVariantChange={handleVariantChange}
                isProduct={isProduct}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

ProductModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  productId: PropTypes.string,
};

export default ProductModal;
