import { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import ProductModal from "./ProductModal";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const ProductGridSingle = ({ product, spaceBottomClass }) => {
  const { t } = useTranslation();
  const [modalShow, setModalShow] = useState(false);
  const currency = useSelector((state) => state.currency);
  const finalProductPrice = +(product?.price * currency.currencyRate);

  return (
    <>
      <div className={clsx("product-wrap", spaceBottomClass)}>
        <div className="product-img">
          <Link to={`${process.env.PUBLIC_URL}/product/${product.productId}`}>
            <img
              className="default-img"
              src={product?.productImages[0]}
              alt={product?.productName || "Product image"}
            />
            {product?.productImages?.length > 1 && (
              <img
                className="hover-img"
                src={product?.productImages[1]}
                alt={`${product?.productName} Product alternate view`}
              />
            )}
          </Link>

          <div className="product-action">
            <div className="pro-same-action pro-cart">
              <Link
                to={`${process.env.PUBLIC_URL}/product/${product?.productId}`}
              >
                {t("buy-now-button-text")}
              </Link>
            </div>
            <div className="pro-same-action pro-quickview">
              <button title="Quick View" onClick={() => setModalShow(true)}>
                <i className="pe-7s-look" />
              </button>
            </div>
          </div>
        </div>
        <div className="product-content text-center">
          <h3>
            <Link
              to={`${process.env.PUBLIC_URL}/product/${product?.productId}`}
            >
              {product?.productName}
            </Link>
          </h3>
          <div className="product-price">
            <span>
              {currency.currencySymbol}
              {finalProductPrice.toFixed(2)}
            </span>
          </div>
        </div>
      </div>

      <ProductModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        productId={product?.productId}
        productVariantId={product?.productVariantId}
      />
    </>
  );
};

ProductGridSingle.propTypes = {
  cartItem: PropTypes.shape({}),
  compareItem: PropTypes.shape({}),
  wishlistItem: PropTypes.shape({}),
  currency: PropTypes.shape({}),
  product: PropTypes.shape({}),
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
};

export default ProductGridSingle;
