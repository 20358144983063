import apiService from "./apiService";

const productVariantPictureService = {
  getImagesByVariantId: (variantId) => {
    if (!variantId) {
      return Promise.reject(new Error("Variant ID is required"));
    }
    return apiService.get(
      `/api/admin/productVariantPictures/getDtoByProductVariantId?productVariantId=${variantId}`
    );
  },

  saveProductVariantPicture: (data) => {
    if (!data.productVariantId || !data.imageId) {
      return Promise.reject(
        new Error("Product variant ID and image ID are required")
      );
    }

    const formData = new FormData();
    formData.append("productVariantId", data.productVariantId);
    formData.append("imageId", data.imageId);
    formData.append("order", data.order || 0);

    return apiService.post("/api/admin/productVariantPictures", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  deleteByProductVariantPictureId: (id) => {
    if (!id) {
      return Promise.reject(
        new Error("Product variant picture ID is required")
      );
    }
    return apiService.delete(
      `/api/admin/productVariantPictures/deleteByProductVariantPictureId?productVariantPictureId=${id}`
    );
  },

  deleteByProductVariantId: (variantIds) => {
    if (!variantIds || variantIds.length === 0) {
      return Promise.reject(new Error("Product variant IDs are required"));
    }
    return apiService.post(
      "/api/admin/productVariantPictures/deleteByProductVariantId",
      variantIds
    );
  },
};

export default productVariantPictureService;
